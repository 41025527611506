import React from 'react';
import './styles.css';
import Logo from '../../assets/img/logo.png'

const TitlePages: React.FC = () => {
  return (
      <>
    <img
    src={Logo}
    width={230}
  ></img>
  <div className="title">
    <h1>Meu Óleo Ideal By Workgroup</h1>
  </div>
  </>
  );
}

export default TitlePages;