import React from 'react';
import './styles.css';

const FooterPages: React.FC = () => {
  return (
    <div className="footer">
      <h1>Copyright @ Workgroup - 2020 | 2021 - Todos os direitos reservados</h1>
    </div>
  );
}

export default FooterPages;