import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import MessageBox from '../../components/MessageBox';
import '../../assets/styles/global.css';
import Logo from '../../assets/img/logo.png';
import TitlePages from '../../components/TitlePages';
import FooterPages from '../../components/FooterPages';

interface Year {
  id: number;
  year: number;
  status: boolean;
};

interface BrandAndModel {
  vehicle: string;
  yearInitial: number;
  yearFinal: number;
  id: number;
};

interface Oil {
  description: string;
  technicalData: string;
  id: number;
};

const Home: React.FC = () => {
  const [year, setYear] = useState<string>();
  const [idYear, setIdYear] = useState<number>();
  const [years, setYears] = useState<Year[]>([]);
  const [brand, setBrand] = useState<string>();
  const [brands, setBrands] = useState<BrandAndModel[]>([]);
  const [model, setModel] = useState<string>();
  const [models, setModels] = useState<BrandAndModel[]>([]);
  const [oil, setOil] = useState<string>();
  const [oils, setOils] = useState<Oil[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState(0)
  const [labelOil, setLabelOil] = useState<string>('Selecione o óleo lubrificante');


  useEffect(() => {
    if(sessionStorage.getItem('@MeuOleoIdeal/vehicleYear') !== null){
      
      var vehicleYear = sessionStorage.getItem('@MeuOleoIdeal/vehicleYear')
      var vehicleIdYear = sessionStorage.getItem('@MeuOleoIdeal/vehicleIdYear')
      var vehicleBrand = sessionStorage.getItem('@MeuOleoIdeal/vehicleBrand')
      var vehicleModel = sessionStorage.getItem('@MeuOleoIdeal/vehicleModel')
      var vehicleOil = sessionStorage.getItem('@MeuOleoIdeal/vehicleOil')

      var yearsList = Array(sessionStorage.getItem('@MeuOleoIdeal/yearsList'))
      var modelsList = Array(sessionStorage.getItem('@MeuOleoIdeal/modelsList'))
      var brandsList = Array(sessionStorage.getItem('@MeuOleoIdeal/brandsList'))
      var oilsList = Array(sessionStorage.getItem('@MeuOleoIdeal/oilsList'))

      setIdYear(Number(vehicleIdYear))


      parseYearsList(yearsList[0] || '');
      parseBrandsList(brandsList[0] || '');
      parseModelsList(modelsList[0] || '', vehicleBrand || '');
      parseOilsList(oilsList[0] || '', vehicleModel || '')

      setYear(String(vehicleYear))
      setBrand(String(vehicleBrand))
      setModel(String(vehicleModel))
      setOil(String(vehicleOil))

    }
    else{
      getListYear();
    }
  }, []);

  useEffect(() => {
    if(sessionStorage.getItem('@MeuOleoIdeal/vehicleYear') === null){
      if(!(year)) return
        getBrands(Number(year));
    }
  }, [year]);

  useEffect(() => {
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleBrand')
    if(!(brand)) return
    getModels(idYear || 0, Number(brand));
  }, [brand]);

  useEffect(() => {
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleModel') 
    if(!(model)) return
    getOils(Number(model), idYear || 0);
  }, [model]);

  async function getListYear() {
    await api.get<Year[]>('Users/ListYear')
      .then(resp => setYears(resp.data.sort((a, b) => b.year - a.year)))
      .catch(() => setYears([]));
  };

  async function getBrands(parametro: number) {
    await api.post<BrandAndModel[]>('Users/ListBrands', { parametro })
      .then(resp => setBrands(resp.data))
      .catch(() => setBrands([]));
  };

  async function getModels(idYear: number, idBrand: number) {
    await api.post<BrandAndModel[]>('Users/ListModels', {
      idYear,
      idBrand
    })
      .then(resp => setModels(resp.data))
      .catch(() => setModels([]));
  };

  async function getOils(idVehicle: number, idYear: number) {
    await api.post<Oil[]>('Users/ListProduts', {
      idVehicle,
      idYear
    })
      .then(resp => {

        setOils(resp.data)
        if (resp.data.length < 1) {
          setLabelOil('Não existem óleos para esse modelo')
        } else {
          setLabelOil('Selecione o óleo lubrificante')
        }
      })

      .catch(() => {
        setOils([])
        setLabelOil('Não existem óleos para esse modelo')
      });
  };

  function handleIdYear() {
    let selectedYear = years.find(y => y.year === Number(year))
    setIdYear(selectedYear?.id);
  }

  function parseYearsList(yearsList: string){
    try{
      let jsonParse = JSON.parse(yearsList)
      setYears(jsonParse); 
    } catch(err) {
      return getListYear();
    }
  }

  function parseBrandsList(brandsList: string){
    try{
      let jsonParse = JSON.parse(brandsList)
      setBrands(jsonParse); 
    } catch(err) {
      return getBrands(Number(year));
    }
  }

  function parseModelsList(modelsList: string, vehicleBrand: string){
    try{
      let jsonParse = JSON.parse(modelsList)
      setModels(jsonParse); 
    } catch(err) {
      return getModels(idYear || 0, Number(vehicleBrand));
    }
  }

  function parseOilsList(oilsList: string, vehicleModel: string){
    try{
      let jsonParse = JSON.parse(oilsList)
      setOils(jsonParse); 
    } catch(err) {
      return getOils(Number(vehicleModel), idYear || 0);
    }
  }

  function handleSearch() {
    if (!year) {
      setModalOpen(true);
      setMessage(1);
      return;
    }
    if (!brand) {
      setModalOpen(true);
      setMessage(2);
      return;
    }
    if (!model) {
      setModalOpen(true);
      setMessage(3);
      return;
    }
    if (!oil) {
      setModalOpen(true);
      setMessage(4);
      return;
    }

    sessionStorage.setItem('@MeuOleoIdeal/vehicleIdYear', String(idYear))
    sessionStorage.setItem('@MeuOleoIdeal/vehicleYear', year)
    sessionStorage.setItem('@MeuOleoIdeal/yearsList', JSON.stringify(years))
    sessionStorage.setItem('@MeuOleoIdeal/vehicleBrand', brand)
    sessionStorage.setItem('@MeuOleoIdeal/brandsList', JSON.stringify(brands))
    sessionStorage.setItem('@MeuOleoIdeal/vehicleModel', model)
    sessionStorage.setItem('@MeuOleoIdeal/modelsList', JSON.stringify(models))
    sessionStorage.setItem('@MeuOleoIdeal/vehicleOil', oil)
    sessionStorage.setItem('@MeuOleoIdeal/oilsList', JSON.stringify(oils))

    window.location.pathname = '/address';
  };

  return (
    <div className="container">
      <TitlePages />
      {}
      <select name="selectYear" id="selectYear" value={year} onChange={
        value => {setYear(String(value.target.value))}
      }>
        <option value=''>Selecione o ano do veículo</option>
        {years.map(year => {
          return <option key={year.id} value={year.year}>{year.year}</option>
        })}
      </select>
      <select name="selectBrand" id="selectBrand" value={brand} onClick={handleIdYear} onChange={
        value => setBrand(String(value.target.value))
      }>
        <option value=''>Selecione a marca do veículo</option>
        {brands.map(brand => {
          return <option key={brand.id} value={brand.id}>{brand.vehicle}</option>
        })}
      </select>
      <select name="selectModel" id="selectModel" value={model} onChange={
        value => setModel(String(value.target.value))
      }>
        <option value=''>Selecione o modelo do veículo</option>
        {models.map(model => {
          return <option key={model.id} value={model.id}>{model.vehicle}</option>
        })}
      </select>
      <select name="selectOil" id="selectOil" value={oil} onChange={
        value => setOil(String(value.target.value))
      }>
        <option value=''>{labelOil}</option>
        {oils.map(oil => {
          return <option key={oil.id} value={oil.id}>{oil.description}</option>
        })}
      </select>
      <button id='continue' onClick={handleSearch}>AVANÇAR</button>
      <FooterPages />

      <div>
        {modalOpen && message === 1 ? (<MessageBox message="Selecione o ano do veículo." onClose={() => { setModalOpen(false) }} />
        ) :
          null}
      </div>
      <div>
        {modalOpen && message === 2 ? (<MessageBox message="Selecione a marca do veículo." onClose={() => { setModalOpen(false) }} />
        ) :
          null}
      </div>
      <div>
        {modalOpen && message === 3 ? (<MessageBox message="Selecione o modelo do veículo." onClose={() => { setModalOpen(false) }} />
        ) :
          null}
      </div>
      <div>
        {modalOpen && message === 4 ? (<MessageBox message="Ops, não foi encontrado o óleo, por favor, altere os filtros e pesquise novamente." onClose={() => { setModalOpen(false) }} />
        ) :
          null}
      </div>
    </div>
  );
}

export default Home;
