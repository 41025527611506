import React, { useState, useEffect } from 'react';
import './styles.css'

interface Props {
    onClose(): void;
    message: string;
};
const MessageBox: React.FC<Props> = ({message, onClose }) => {

    return (
        <div id="MessageBox" className="MessageBox">

            <div className="errorMessageBox-modal">
               
                <div className="MessageBox-Title">
                    <h1>Meu Óleo Ideal</h1>
                </div>
                <div className="Error-Message">
                    <h3>{message}</h3>
                </div>
                <div className='error-message-button'>
                    <div className='button-below-page'>
                        <button onClick={() => { onClose() }} >VOLTAR</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MessageBox;