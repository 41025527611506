import React, { useState, useEffect } from 'react';
import cep from 'cep-promise';
import ReactLoading from 'react-loading';

import { cepMask } from '../../utils/normalize'
import MessageBox from '../../components/MessageBox';
import AddressBox from '../../components/AddressBox';
import TitlePages from '../../components/TitlePages';
import FooterPages from '../../components/FooterPages';
import '../../assets/styles/global.css';
import './styles.css';

interface Address {
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  short_state: string;
};

const Address: React.FC = () => {

  const [zipCode, setZipCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [addressCepPromise, setaddressCepPromise] = useState<Address>({} as Address);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [isSucessModalVisible, setIsSucessModalVisible] = useState(false);
  const [message, setMessage] = useState('');

  async function getAddress() {

    if (!zipCode) return handleErrorMessage('Ops, informe o CEP para prosseguir.')

    if (zipCode.length < 10) return handleErrorMessage('Ops, o CEP informado é inválido.')

    setLoading(true);

    await cep(zipCode)
      .then(async resp => {

        const obj = {
          short_state: resp.state,
          city: resp.city,
          address: resp.street,
          neighborhood: resp.neighborhood,
          state: resp.state
        };

        setaddressCepPromise(obj);
        handleSucessMessage();

      })
      .catch(() => {
        handleErrorMessage("Ops, o CEP informado é inválido.")
      });
    setLoading(false);
  };

  function handleErrorMessage(message: string){
    setMessage(message)
    setIsErrorModalVisible(true)
  }

  function handleSucessMessage(){
    setIsSucessModalVisible(true)
  }

  function submitAddress(){

    sessionStorage.setItem('@MeuOleoIdeal/city', addressCepPromise.city)
    sessionStorage.setItem('@MeuOleoIdeal/state',addressCepPromise.short_state)

    return window.location.pathname = '/reseller'
  }

  function goBack(){
    return window.location.pathname = '/'
  }

  function clearStates(){
    setIsSucessModalVisible(false)
    setZipCode('')
  }


  return (
    <>
      <div className='container'>
        <TitlePages/>
          <input className='address-input'
                    type="text"
                    placeholder="Qual é o seu CEP?"
                    value={zipCode}
                    onChange={event => setZipCode(cepMask(event.target.value))}
          />
       <button id='continue' onClick={() => getAddress()}>BUSCAR</button>
       <button id='continue-danger' onClick={() => goBack()}>VOLTAR</button>
       {ReactLoading && loading ?
            <ReactLoading className='loading' type={'spinningBubbles'} color={'white'} height={'auto'} width={'4%'}/>
            : <div>
            </div>
          }

      <div className='footer-address-page'>
          <FooterPages/>
      </div>
      </div>
      <div>
          {isErrorModalVisible ? (<MessageBox message= {message} onClose={() => { setIsErrorModalVisible(false) }} />
          ) :
            null}
      </div>
      <div>
          {isSucessModalVisible ? (<AddressBox addressInfo= {addressCepPromise} onSubmit={() => { submitAddress() }} onClose={() => { clearStates() }} />
          ) :
            null}
      </div>
    </>
);
}

export default Address;