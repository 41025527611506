import React, { useState, useEffect } from 'react';
import './styles.css';
import '../../assets/styles/global.css';

interface Address {
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    short_state: string;
};

interface Props {
    onClose(): void;
    onSubmit(): void;
    addressInfo: Address;
};

const AddressBox: React.FC<Props> = ({addressInfo, onClose, onSubmit}) => {

    const [address, setAddress] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [shortState, setShortState] = useState('')

    useEffect(() => {
        if(addressInfo !== null || addressInfo !== undefined)
        handleData(addressInfo);
    }, []);

    function handleData(addressInfo : Address){
        return setAddress(addressInfo.address),
        setNeighborhood(addressInfo.neighborhood),
        setCity(addressInfo.city),
        setShortState(addressInfo.short_state)
    }

    return (
        <div id="AddressBox" className="AddressBox">

            <div className="addressBox-info">
                <div className='address-message-info'>
                    <h3>{address}</h3>
                    <h3>Bairro: {neighborhood}</h3>
                    <h3>Cidade: {city}</h3>
                    <h3>Estado: {shortState}</h3>
                </div>
                <div className='addressBox-buttons'>
                    <div className='return-button-page' style={{paddingRight: '10px'}}> 
                        <button onClick={() => { onClose() }} >VOLTAR</button>
                    </div>
                    <div className='confirm-button-page'>
                        <button onClick={() => { onSubmit() }} >CONFIRMAR</button>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default AddressBox;