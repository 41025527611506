import React, { useEffect, useState } from 'react';
import './styles.css';
import TitlePages from '../../components/TitlePages'
import FooterPages from '../../components/FooterPages';
import api from '../../services/api'

interface ResponseReseller {
  name: string;
  email: string;
  cellphone: string;
  message: string;
  addressComplete: string;
  numberAddress: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: string;
  longitude: string;
  id: number;
};

const Reseller: React.FC = () => {

  const [listResellers, setResellers] = useState<ResponseReseller[]>([]);

  useEffect(() => {
    getResellers()
  }, []);

  async function goHome() {
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleYear')
    sessionStorage.removeItem('@MeuOleoIdeal/yearsList')
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleIdYear')
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleBrand')
    sessionStorage.removeItem('@MeuOleoIdeal/brandsList')
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleModel')
    sessionStorage.removeItem('@MeuOleoIdeal/modelsList')
    sessionStorage.removeItem('@MeuOleoIdeal/vehicleOil')
    sessionStorage.removeItem('@MeuOleoIdeal/oilsList')
    window.location.pathname = '/'
  }

  async function getResellers() {

    const obj = {
      city: sessionStorage.getItem('@MeuOleoIdeal/city'),
      state: sessionStorage.getItem('@MeuOleoIdeal/state')
    };

    await api.post<ResponseReseller[]>('Users/Resellers', obj)
      .then(resp => setResellers(resp.data))
      .catch(() => setResellers([]));
    sessionStorage.removeItem('@MeuOleoIdeal/city')
    sessionStorage.removeItem('@MeuOleoIdeal/state')
  };

  async function formtValue(value: string) {
    while (value.includes("'")) {
      value = value.replace("'", "");
    };
    return value;
  };

  return (
    <div className="container-reseller">
      <div style={{ textAlign: "center", width: "200%", height: "100px" }}>
        <TitlePages />
      </div>
      {listResellers.length ?
        <div className="list-resellers">

          {listResellers.map((reseller, index) => (
            <div className="list-resellers-card">
              <h1>{reseller.name.trim()}</h1>
              <h2>{reseller.addressComplete}, {reseller.numberAddress}</h2>
              <h2>{reseller.city}-{reseller.state}</h2>


            </div>
          )
          )}
          <button className="new-search-button" onClick={() => goHome()}>REALIZAR NOVA BUSCA</button>
          <FooterPages />
        </div>
        :
        <div className="list-resellers">

          <div className="list-resellers-card-null">
            <h1 style={{ textAlign: "center" }}>Não existe revendedores para esse óleo no Cep pesquisado.</h1>
          </div>
          <button className="new-search-button" onClick={() => goHome()}>REALIZAR NOVA BUSCA</button>
          <FooterPages />
        </div>
      }


    </div>
  );
}

export default Reseller;