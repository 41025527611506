import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Home from './pages/Home'
import Address from './pages/Address'
import Reseller from './pages/Reseller'

function Routes() {
    return(
        <BrowserRouter>
            <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/address" component={Address} />
            <Route path="/reseller" component={Reseller} />
            </Switch>        
        </BrowserRouter>
    );
};

export default Routes;